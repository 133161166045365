<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/blog' }">{{$t('message.blog')}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    {{languageName=='cn'?articleObj.cn_title:''}}
                    {{languageName=='en'?articleObj.en_title:''}}
                    {{languageName=='it'?articleObj.it_title:''}}
                </el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <b-container class="article">
            <div class="title">
                {{languageName=='cn'?articleObj.cn_title:''}}
                {{languageName=='en'?articleObj.en_title:''}}
                {{languageName=='it'?articleObj.it_title:''}}
            </div>
            <b-row class="bore">
                <b-col class="times">{{articleObj.create_time}}</b-col>
                <b-col class="oths">
                    {{$t('message.shareto')}}：
                    <span class="shareicon ins" @click="getShare('ins')"></span>
                    <span class="shareicon dy" @click="getShare('tiktok')"></span>
                    <span class="shareicon wxshare" @click="getShare('weixin')"></span>
                </b-col>
            </b-row>
            <div class="wap-bore">
                <div class="wap-times">{{articleObj.create_time}}</div>
                <div class="wap-oths">
                    {{$t('message.shareto')}}：
                    <span class="wap-shareicon wap-ins" @click="getShare('ins')"></span>
                    <span class="wap-shareicon wap-dy" @click="getShare('tiktok')"></span>
                    <span class="wap-shareicon wap-wxshare" @click="getShare('weixin')"></span>
                </div>
            </div>
            <div class="content" v-show="languageName=='cn'" v-html="articleObj.cn_content"></div>
            <div class="content" v-show="languageName=='en'" v-html="articleObj.en_content"></div>
            <div class="content" v-show="languageName=='it'" v-html="articleObj.it_content"></div>
            <div class="contentshart mtg">{{$t('message.shareto')}}：</div>
            <div class="contentshart mbg">
                <span class="shareicon ins" @click="getShare('ins')"></span>
                <span class="shareicon dy" @click="getShare('tiktok')"></span>
                <span class="shareicon wxshare" @click="getShare('weixin')"></span>
            </div>
            <div class="comme">{{$t('message.comments')}}（{{rows}}）</div>
            <div class="commetext">
                <div class="comtes"><input type="text" v-model="commentName" :placeholder="$t('message.recoment')"></div>
                <div class="combtn" @click="getSubmitComment">{{$t('message.submitbtn')}}</div>
            </div>
            <div class="commelist">
                <div class="commelist-group" v-for="(item,index) in listdata" :key="index">
                    <div class="commelist-group-left">
                        <div class="name">{{item.family_name}}{{item.name}}</div>
                        <div class="times">{{item.create_time}}</div>
                    </div>
                    <div class="commelist-group-right">{{item.content}}</div>
                </div>
            </div>
            <div class="wap-comme">{{$t('message.comments')}}（{{listdata.length}}）</div>
            <div class="wap-commetext">
                <div class="wap-comtes"><input type="text" v-model="commentName" :placeholder="$t('message.recoment')"></div>
                <div class="wap-combtn" @click="getSubmitComment">{{$t('message.submitbtn')}}</div>
            </div>
            <div class="wap-commelist">
                <div class="wap-commelist-group" v-for="(item,index) in listdata" :key="index">
                    <div class="wap-commelist-group-left">
                        <div class="wap-name">{{item.family_name}}{{item.name}}</div>
                        <div class="wap-times">{{item.create_time}}</div>
                    </div>
                    <div class="wap-commelist-group-right">{{item.content}}</div>
                </div>
            </div>
            <div class="pc-page" v-show="rows>size">
                <b-pagination :prev-text="$t('message.prev')" :next-text="$t('message.next')" v-model="currentPage" :total-rows="rows" :per-page="size" @change="handleCurrentChange"></b-pagination>    
            </div>
            <div class="wap-page" v-show="currentPage!=totalPage" @click="getPage">{{$t('message.seemore')}}</div>
        </b-container>
        <!-- 微信二维码弹窗 -->
        <b-modal size="sm" centered v-model="codeqrflag" header-class="justify-content-center" hide-footer :title="$t('message.wcharttitle')" @hidden="getClose">
            <div class="qr_wrap">
                <vue-qr :text="text_url" :size="200"></vue-qr>
            </div>
        </b-modal>
    </div>
</template>
<script>
import vueQr from 'vue-qr';
import ElementUI from 'element-ui';
import {yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'blogDetail',
    inject:['reload'],
    components:{
        vueQr
    },
    data() {
        return {
            languageName:'',
            languageObj:{},
            codeqrflag:false,
            text_url:'',
            token:'',
            currentPage:1,//当前页
            size:10,//每页条数
            rows:0,//总条数
            totalPage:0,//总页数
            articleid:'',
            commentName:'',
            articleObj:{},
            listdata:[
                // {
                //     article_id: 30
                //     content: "超赞同"
                //     create_time: "2022-06-12 19:11:17"
                //     family_name: "1"
                //     id: 13
                //     name: "1"
                //     sort: 0
                //     user_id: 11
                // }
            ]
        }
    },
    methods: {
        // 时间转换
        getDateTime(date){
            var date = new Date();
                var seperator1 = "-";
                var seperator2 = ":";
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var strDate = date.getDate();
                var hours = date.getHours(); //小时 
                var mnutes = date.getMinutes();//分 
                var seconds = date.getSeconds();//秒
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                if (hours >= 1 && hours <= 9) {
                    hours = "0" + hours;
                }
                if (mnutes >= 0 && mnutes <= 9) {
                    mnutes = "0" + mnutes;
                }
                if (seconds >= 0 && seconds <= 9) {
                    seconds = "0" + seconds;
                }
                var currentdate = year + seperator1 + month + seperator1 + strDate+' '+ hours +seperator2+ mnutes +seperator2+ seconds;
                return currentdate;

        },
        // 提交文章评论
        getSubmitComment(){
            if(this.token){
                let params = {
                    articleid:parseInt(this.articleid),
                    token:this.token,
                    content:this.commentName,
                }
                this.$http.api_article_fromcomment(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.commentName = '';
                                params.family_name = getLocalItem('login_user_info').family_name;
                                params.name = getLocalItem('login_user_info').name;
                                params.create_time = this.getDateTime(new Date());
                                let arr = [params];
                                this.listdata = arr.concat(this.listdata);
                                this.getAddBrowseNumber(this.articleid,3);//增加类型 1 分享 2 浏览 3 评论
                                // ElementUI.Message({
                                //     message:'评论成功',
                                //     type:'success',
                                //     center: true,
                                //     offset:10,
                                // });
                            }
                        }else if(res.code==-1){
                            // this.reload();
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }else{
                this.$store.commit('setLoginStatus',true);
            }
        },
        // 增加数量（分享  浏览 评论）
        getAddBrowseNumber(id,type){
            this.$http.api_article_addnum({
                id:id,
                type:type,//增加类型 1 分享 2 浏览 3 评论
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            
                        }
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        // return false;
                    }
                }
            });
        },
        facebook() {
            // 初始化    
            FB.init({
                appId:'301546955432179',
                autoLogAppEvents: true,
                xfbml: true,
                version: "v1.01"
            });
            FB.ui(
                {
                    method: "share",
                    mobile_iframe: true,
                    href: "http://dev.XXXX.io/?test=12345"
                },
                function(response) {
                    console.log(response)
                }
            );
        },
        triggerLoginCheck() {       
            FB.getLoginStatus(function(response) {
                statusChangeCallback(response);
            });
        },
        // 关闭弹窗
        getClose(){
            this.codeqrflag = false;
        },
        // 分享
        getShare(type){
            // if(this.token){
                if(type=='weixin'){
                    this.codeqrflag = !this.codeqrflag;
                    var host = location.href;//.split('#')[0]
                    this.text_url = host;
                    this.getAddBrowseNumber(this.articleid,1);//增加类型 1 分享 2 浏览 3 评论
                }else{
                    var host = location.href;//.split('#')[0]
                    this.$copyText(host).then(()=>{
                        ElementUI.Message({
                            message:this.languageObj.copytip,
                            type:'success',
                            center: true,
                            offset:10,
                        });
                        this.getAddBrowseNumber(this.articleid,1);//增加类型 1 分享 2 浏览 3 评论
                    }).catch(()=>{
                        ElementUI.Message({
                            message:this.languageObj.copyfiledtip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                    });
                } 
            // }else{
            //     this.$store.commit('setLoginStatus',true);
            // }
        },
        // 分页
        handleCurrentChange(page){
            this.getCommonet(page)
        },
        // wap分页
        getPage(){
            if(this.currentPage<this.totalPage){
                this.currentPage = this.currentPage+1;
            }
            this.$http.api_article_comment({
                id:this.articleid,
                page:this.currentPage,
                size:this.size
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.rows = res.data.count;
                            this.totalPage = res.data.totalpage;
                            if(this.currentPage>1){
                                this.listdata = this.listdata.concat(res.data.list);
                            }else{
                                this.listdata = res.data.list;
                            }
                            if(this.rows<this.size){
                                return;
                            }
                            if(this.currentPage == this.totalPage){
                                return;
                            }
                        }
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        // return false;
                    }
                }
            })
        },
        // 文章评论
        getCommonet(page){
            this.$http.api_article_comment({
                id:this.articleid,
                page:page,
                size:this.size
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.rows = res.data.count;
                            this.totalPage = res.data.totalPage;
                            this.listdata = res.data.list;
                        }
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        // return false;
                    }
                }
            });
        },
        // 文章详情
        getArticleInfo(articleid){
            this.$http.api_article_info({
                id:articleid
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.articleObj = res.data;
                        }
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        // return false;
                    }
                }
            });
        }
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "copytip":"复制成功",
                "copyfiledtip":"复制失败",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "copytip":"Copy succeeded",
                "copyfiledtip":"copy failed",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "copytip":"Copia riuscita",
                "copyfiledtip":"copia non riuscita",
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
        }
        let routes = this.$route.query;//地址栏参数
        if(routes){
            if(routes.id){
                this.articleid = routes.id;
                this.getArticleInfo(routes.id);//文章详情
                this.getAddBrowseNumber(routes.id,2);//增加类型 1 分享 2 浏览 3 评论
            }
        }
        this.getCommonet(1);//文章相关评论列表数据
    }
}
</script>
<style lang="scss" scoped>
// pc端分页样式优化
/deep/.page-item{
    .page-link:focus, 
    .page-link:active:focus, 
    .page-link.active:focus,
    .page-link.focus,
    .page-link:active.focus,
    .page-link.active.focus {
        outline: none;
        box-shadow:none;
    }
    &:nth-child(1){
        display: none;
        .page-link{
            color: transparent;
        }
        .page-link::before{
            content: '首页';
            padding-left:8px;
            color: #616166;
        }
    }
    &:nth-child(2){
        // .page-link{
        //     color: transparent;
        // }
        .page-link::before{
            // content: v-bind(langageprev);//'上一页';
            padding-left:5px;
            color: #616166;
        }
    }
    &:last-child{
        display: none;
        .page-link{
            color: transparent;
        }
        .page-link::before{
            content: '尾页';
            padding-left:8px;
            color: #616166;
        }
    }
    &:nth-last-child(2){
        // .page-link{
        //     color: transparent;
        // }
        .page-link::before{
            // content: v-bind(langagenext);//'下一页';
            padding-left:5px;
            color: #616166;
        }
    }
}
/deep/.page-item:first-child .page-link{
    border-radius: 0;
}
/deep/.page-item:last-child .page-link{
    border-radius: 0;
}
/deep/.page-link{
    border:1px solid #DDE0ED;
    margin: 0 4px;
    padding:6px 10px;
    
    color: #616166;
}
/deep/.page-item.active .page-link{
    color: #007bff;
    background-color: #fff;
    border-color: #fff;
    outline: none;
}

.qr_wrap{
    img{
        width:100%;
    }
}
.navtab{
    padding:20px 10px 10px;
    
}
.article{
    padding:20px;
    .title{
        font-size:16px;
        text-align: center;
    }
    .bore{
        padding:30px 0 20px;
        margin-bottom: 20px;
        border-bottom:1px solid #dde0ed;
    }
    .times{
        color:#a5a5ad;
    }
    .oths{
        text-align: right;
        .shareicon{
            cursor: pointer;
            display: inline-block;
            width: 20px;
            height:20px;
            margin: 0 10px;
            vertical-align: middle;
        }
        .ins{
            background: url(~@/assets/images/icon_Instagram.png) no-repeat left top;
            background-size: 100% 100%;
        }
        .dy{
            background: url(~@/assets/images/icon_tictok.png) no-repeat left top;
            background-size: 100% 100%;
        }
        .wxshare{
            background: url(~@/assets/images/icon_wchart.png) no-repeat left top;
            background-size: 100% 100%;
        }
    }
    .content{
        padding:10px 0;
        line-height: 30px;
    }
    .comme{
        font-size:16px;
        padding:10px 20px;
        background: #f4f7fe;
    }
    .commetext{
        width: 100%;
        height:50px;
        line-height:50px;
        margin:15px 0;
        display: flex;
        -webkit-box-orient: horizontal;
        .comtes{
            width: 82%;
            height:50px;
            border-radius: 4px;
            border:1px solid #eee;
            margin:0 2%;
            input{
                border:none;
                width: 100%;
                height:100%;
                padding:0 10px;
                outline: none;
                background: transparent;
            }
        }
        .combtn{
            width: 15%;
            border-radius: 4px;
            text-align: center;
            cursor: pointer;
            background: #1f73b7;
            color: #fff;
            
        }
    }
    .contentshart{
        font-size:16px;
        padding:10px 0;
        text-align: center;
        .shareicon{
            cursor: pointer;
            display: inline-block;
            width: 40px;
            height:40px;
            margin: 0 15px;
            vertical-align: middle;
        }
        
        .ins{
            background: url(~@/assets/images/icon_Instagram.png) no-repeat left top;
            background-size: 100% 100%;
        }
        .dy{
            background: url(~@/assets/images/icon_tictok.png) no-repeat left top;
            background-size: 100% 100%;
        }
        .wxshare{
            background: url(~@/assets/images/icon_wchart.png) no-repeat left top;
            background-size: 100% 100%;
        }
    }
    .mtg{
        margin-top: 20px;
    }

    .mbg{
        margin-bottom: 20px;
    }
    .commelist{
        .commelist-group{
            display: flex;
            -webkit-box-orient: horizontal;
            border-bottom:1px solid #eee;
            padding:10px 20px;
            .commelist-group-left{
                width:20%;
                .name{
                    
                }
                .times{
                    color:#95959e;
                    margin-top: 10px;
                }
                
            }
            .commelist-group-right{
                width:80%;
                color: #616166;
                line-height: 30px;
            }
        }
    }
    .pc-page{
        display: flex;
        -webkit-box-orient: horizontal;
        justify-content: center;
        padding:20px 0;
    }
    .wap-page{
        display: flex;
        -webkit-box-orient: horizontal;
        justify-content: center;
        padding:20px 0;
    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-bore{display: none;}
        .wap-comme{display: none;}
        .wap-commetext{display: none;}
        .wap-commelist{display: none;}
        .wap-page{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-bore{display: none;}
        .wap-comme{display: none;}
        .wap-commetext{display: none;}
        .wap-commelist{display: none;}
        .wap-page{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-bore{display: none;}
        .wap-comme{display: none;}
        .wap-commetext{display: none;}
        .wap-commelist{display: none;}
        .wap-page{display: none;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .wap-bore{display: none;}
        .wap-comme{display: none;}
        .wap-commetext{display: none;}
        .wap-commelist{display: none;}
        .wap-page{display: none;}
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .article{
            padding: 0 0px;
            .title,.content{
                padding-left: 10px;
                padding-right: 10px;
            }
            
        }
        .pc-page{display: none;}
        .bore{display: none;}
        .comme{display: none;}
        .commetext{display: none;}
        .commelist{display: none;}
        .wap-bore{
            padding:0 10px;
            margin:0;
            .wap-times{
                padding:10px 0;
                color:#a5a5ad;
            }
            .wap-oths{
                .wap-shareicon{
                    cursor: pointer;
                    display: inline-block;
                    width: 20px;
                    height:20px;
                    margin: 0 10px;
                    vertical-align: middle;
                }
                .wap-ins{
                    background: url(~@/assets/images/icon_Instagram.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .wap-dy{
                    background: url(~@/assets/images/icon_tictok.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .wap-wxshare{
                    background: url(~@/assets/images/icon_wchart.png) no-repeat left top;
                    background-size: 100% 100%;
                }
            }
            
        }
        .wap-comme{
            
            padding:10px 10px;
            background: #f4f7fe;
        }
        .wap-commetext{
            width: 100%;
            margin-top: 10px;
            height:40px;
            line-height:40px;
            display: flex;
            -webkit-box-orient: horizontal;
            padding:0 10px;
            .wap-comtes{
                width: 83%;
                height:40px;
                border-radius: 4px;
                margin-right: 2%;
                border:1px solid #eee;
                input{
                    border:none;
                    width: 100%;
                    height:100%;
                    padding:0 10px;
                    outline: none;
                    background: transparent;
                }
            }
            .wap-combtn{
                width: 15%;
                border-radius: 4px;
                text-align: center;
                background: #1f73b7;
                color: #fff;
                
            }
        }
        .wap-commelist{
            padding:10px ;
            .wap-commelist-group{
                border-bottom:1px solid #eee;
                padding:10px 0;
                width: 100%;
                .wap-commelist-group-left{
                    width:100%;
                    .wap-name{
                        
                    }
                    .wap-times{
                        color:#95959e;
                        margin-top: 10px;
                    }
                    
                }
                .wap-commelist-group-right{
                    width:100%;
                    color: #616166;
                    line-height: 30px;
                }
            }
        }
    }
}
</style>